<template>
  <div class="page">
    <div class="c-page o-page-wrapper">
      <Sidebar :status="'about'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <Content></Content>
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/about/WhoWeAreContent.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'

export default {
  name: 'About',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    GetStartedContent,
    LikeSuggestion
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Bespoke Wealth.jpg'),
          title:"<span class='c-page-banner__bespoke-title'>Bespoke Digital <br /> Asset Solutions</span>",
          subtitle:"<span class='c-page-banner__bespoke-subtitle'>To achieve your growth objectives</span>",
          buttonText:"Get started >",
          buttonPage:"/institutional/wealth"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Established Digital Asset Solutions Partner')
    this.updateDescriptionMeta('Sparrow is the trusted partner in digital asset solutions for institutions and accredited clients seeking to deploy capital in cryptocurrencies in Singapore.')
    this.updateKeywordMeta('Sparrow, Sparrow, wealth management, options trading platform, cryptocurrencies investment')
  }
}
</script>
